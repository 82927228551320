import { useState } from 'react';
import axios from 'axios'
import inMemoryJWT from '../../../inMemoryJwt.js'
import {elementSuccess, elementError} from '../../../store/actions/yourElementsAction.js'
import { useSelector, useDispatch } from 'react-redux';
import { villeData } from '../../../store/actions/caseContentAction.js';
import {refreshTokenGen} from '../../../inMemoryJwt.js';

export const downloadFile = (e, url, locked) =>{
    const token = localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refresh_token');
            // const name= ''
            if(!locked){
                let link = document.createElement('a');
                link.setAttribute('download', 'download');
                link.setAttribute('target', '_blank');
                link.href = url;
                document.querySelector('.downloadSecCnt').appendChild(link);
                link.click();
                link.remove();
            }

    // axios.get(`${process.env.REACT_APP_API_URL}`,  { headers: setAuthorizationToken(token) })
    // .then(response => {
    //     console.log(response);
    //     if (response.code === 401) {
    //         inMemoryJWT.refreshTokenGen(refreshToken).then(challenge => {
    //             if(challenge){
    //                 downloadFile(e, url);
    //             }
    //         });
    //     }
    //    else if(response.data.success){
    //             const name= ''
    //             let link = document.createElement('a');
    //             link.setAttribute('download', name);
    //             link.href = 's';
    //             document.querySelector('.downloadSecCnt').appendChild(link);
    //             link.click();
    //             link.remove();  
    //     }
    // })
    // .catch(error => {
    //     if (error.response.data) {
         
    //     }
    // });
}
export const dateConvert = ( datavalue) =>{
    const dateConvert = datavalue?.split('+')[0];
    const propDate = new Date(dateConvert);
    const newsMonth = propDate.toLocaleString('default', { month: 'long'});
    const newsYear = propDate.getFullYear();
    const newsDate = propDate.getDate();
    return  newsDate + ' ' + newsMonth + ' ' + newsYear;
}
export const eventDateConvert = ( datavalue) =>{
    const dateConvert = datavalue?.split('+')[0];
    const propDate = new Date(dateConvert);
    return  ('0' + propDate.getDate()).slice(-2) + '/'
             + ('0' + (propDate.getMonth()+1)).slice(-2) + '/'
             + propDate.getFullYear();
}

export const addBodyClass = () => {
    if(!document.body.classList.contains('nolayout')){
        document.body.classList.add('nolayout');
        return true;
    } 
    return false;
}

export const removeHtmlClass = () => {
    var root = document.getElementsByTagName( 'html' )[0];
    if(root.classList.contains('caselayout')){
        root.classList.remove('caselayout');
        return true;
    } 
    return false;
}

export const addHTMLClass = () => {
    var root = document.getElementsByTagName( 'html' )[0];
    if(!root.classList.contains('caselayout')){
        root.setAttribute( 'class', 'caselayout' );
        return true;
    }
    return false;
}
export const setAuthorizationToken = (token) => {
    if(token){
        return { Authorization: "Bearer " +  token }
    } else {
        return { Authorization: '' }
    }

}

export const getScrollHeight = (mediaList) =>{
    let headerHeight = document.querySelector("header").clientHeight; 
    let bredCrumbHeight = document.querySelector(".breadCrumbSec").clientHeight;
    let footerHeight = document.querySelector(".footer_Sec").clientHeight;
    let para = document.querySelector('.pageDataSec');
    let compStyles = window.getComputedStyle(para);
    let mypadding = compStyles.getPropertyValue('padding-top')
    mypadding = parseInt(mypadding.replace("px", ""));
    return headerHeight + bredCrumbHeight + footerHeight + mypadding + (mediaList ? 67 : 20);
}

export const suggestedFormEle = (data, id) =>{
    const token = localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refresh_token');
    if (token) {
        axios.post(process.env.REACT_APP_API_URL + '/contact/' + id, {
            "title": data.title,
            "message": data.message
        }, { headers: setAuthorizationToken(token) })
        .then(response => {
            if (response.code === 401) {
                
                inMemoryJWT.refreshTokenGen(refreshToken).then(challenge => {
                    if(challenge){
                        suggestedFormEle(data, id);    
                    }
                })
            } else {
                successSuggestForm();    
            }
        })
        .catch(error => {
            successErrorForm();
        });
    }
}
export const elementsFormEle = (data, pageDetail) => {
    const elementId = pageDetail.slug;
    const token = localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refresh_token');
    if (token) {
        axios.post(process.env.REACT_APP_API_URL + '/contact/' + elementId, {
            "cover": data.cover,
            "delarship": data.delarship,
            "transplant":data.transplant,
            "customerInformation":data.excelUpload
        }, { headers: setAuthorizationToken(token) })
        .then(response => {
            if (response.code === 401) {
                
                inMemoryJWT.refreshTokenGen(refreshToken).then(challenge => {
                    if(challenge){
                        elementsFormEle(data, pageDetail);    
                    }
                })
            } else {
                elementSuccess('success')
            }
        })
        .catch(error => {
            elementError('success')
        });
    }
}
const errorElementsForm = () => {
        return 'errorText';
}
const successElementsForm = () => {
    return 'successText'; 
}
 const successSuggestForm = () => {
    return 'FailureText'; 
}
 const  successErrorForm = () => {
    return 'FailureText';
}
export const selectFieldData = (inputData, users) =>{
    let storeFiled = inputData;
                let selectArray = [];
                storeFiled.filter((item, index) => {
                  let selectObject = {};
                    if(users){
                        selectObject.label = item?.lastName;
                    } else {
                        selectObject.label = item?.title;
                    }
                    selectObject.value = item?.id;
                    selectArray.push(selectObject);
                });
            return selectArray;
}
export const queryParams = (queryParams) => {
               
        let startDateUtc = queryParams.startDate?.toString()
            startDateUtc = startDateUtc?.split("00:00:00")[0];
        let endDateUtc = queryParams.endDate?.toString();
            endDateUtc = endDateUtc?.split("00:00:00")[0];
        if(queryParams){
            let queryString = '';
                
                    if(queryParams.title !== ''){
                        queryString =  '"title":"' + queryParams.title + '"';
                    }
                    if(queryParams.startDate  !== '' && queryParams.endDate  !== ''){
                        queryString += ',"date": [' + ['"' + new Date(startDateUtc + "05:35:32")?.toISOString() +'"',  '"' +new Date(endDateUtc + "05:35:32")?.toISOString()+'"' ] + ']';
                    }
                    if(queryParams.theme  !== ''){
                        queryString += ',"theme":{"id":' + queryParams.theme + '}';
                    }
                    if(queryParams.subtheme  !== ''){
                        queryString += ',"subtheme":{"id":' + queryParams.subtheme + '}';
                    }
                    if(queryParams.dpt  !== ''){
                        queryString += ',"dpt":"' + queryParams.dpt + '"';
                    }
                    if(queryParams.town  !== ''){
                        queryString += ',"town":"' + queryParams.town + '"';
                    }
                    if(queryParams.dealer  !== ''){
                        queryString += ',"dealer":"' + queryParams.dealer + '"';
                    }
                    if(queryParams.service  !== ''){
                        queryString += ',"service":{"id":' + queryParams.service + '}';
                    }
                    if((queryParams.tags).length > 0){
                        let queryParamsTag =  queryParams.tags;
                        let tagArr = [];
                        queryParamsTag.filter((item, index) => {
                            tagArr.push('{"id":'+ item.value + '}');
                        });
                        queryString += ',"tags":[' + tagArr + ']'; 
                    }
            return queryString;
        }
        return '';
}
export const getFullMedia = (e, caseId, title) => {
    document.querySelector('.downloadSec')?.classList?.add('active');
    const token = localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refresh_token');
     fetch(`${process.env.REACT_APP_API_URL}/event/download/${caseId}`, {headers: setAuthorizationToken(token)})
     .then(response => {
        if (response.status === 401) {
            refreshTokenGen(refreshToken).then(challenge => {
                if(challenge){
                    getFullMedia(e, caseId, title);    
                }
            })
        } else {
            return response.blob();
        }
    })
     .then(blob => {
        document.querySelector('.downloadSec')?.classList?.remove('active');
         var url = window.URL.createObjectURL(blob);
         var a = document.createElement('a');
         a.href = url;
         a.download = `${title.replace(/[^a-z0-9]/gi, '_').toLowerCase()}.zip`;
         document.body.appendChild(a);
         a.click();    
         a.remove();         
     });
}

export const getStateData = async  (department, auth) => {
    var villeList = [];
    axios.get(` https://www.universal-tutorial.com/api/cities/${department}`, { headers: {Authorization: "Bearer " +  auth}})
    .then(response => {
            const stateList = response.data;
            
            if(stateList.length > 0){
                for(var i = 0; i < stateList.length; i++){
                        let eachItem = stateList[i].city_name;
                        var newObj = {
                                "label":eachItem,
                                "value":eachItem
                        }
                       villeList.push(newObj);
                }
            } else{
                var newObj = {
                    "label":auth,
                    "value":auth
                }
             villeList.push(newObj);     
            }
        
    })
    .catch(error =>{
        console.log(error);
      })
      return villeList;
}
