import { useState, useEffect, useRef, useCallback } from 'react';
import {Link, useNavigate} from "react-router-dom";
import Header from '../../header/index';
import Footer from '../../footer/index';
import SearchForm from '../../common/searchForm/index';
import PreviewModal from '../../common/previewModal/index';
import { Modal} from 'bootstrap';
import axios from "axios";
import { useAuthenticated  } from 'react-admin';
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "./../../common/Loader/index";
import { Scrollbar } from 'react-scrollbars-custom';
import {downloadFile, addBodyClass, setAuthorizationToken, addHTMLClass, dateConvert, getScrollHeight, getFullMedia, eventDateConvert, queryParams} from '../../common/utils';
import { useSelector, useDispatch } from 'react-redux';
import { contentData, searchEnabled, searchFormEle } from '../../../store/actions/allaction.js';
import {refreshTokenGen} from '../../../inMemoryJwt.js';

function CaseContentPage() {
    useAuthenticated();
    let $is_loading_more  = false;
    let formParams = useSelector((state) => state.casecontent.formData);
    let dispatch = useDispatch()
    const [items, setItems] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isFilter, setIsFilter] = useState({filterEnable:false, filterData:''});
    const [pagi, setPagi] = useState(2);
    const loaderRef = useRef(null);
    const [modalImg, setImgUrl] = useState({title:'', imgUrl:''});
    const [winHeight, setWinHeight] = useState();
    const [calcHeight, setCalcHeight] = useState();

    const previewModal = (e, titleTxt, modalUrl) => {
        setImgUrl({title:titleTxt, imgUrl:modalUrl});
        let myModal = new Modal(document.getElementById('previewModalCenter'));
        myModal.show();
    }
    useEffect(() => {
        function handleResize() {
            let calcHeight = getScrollHeight();
            setCalcHeight(calcHeight);
            let sideBarHeight = document.querySelector(".searchFormContainer").clientHeight;
            let winHeight = window.innerHeight;
            if((calcHeight - winHeight) < sideBarHeight){
                winHeight = sideBarHeight + (calcHeight - 20)
            }
            setWinHeight(winHeight);
        }
        handleResize();
        addBodyClass();
        addHTMLClass();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, []);
    useEffect(() => {
        const tokenExpires = localStorage.getItem('tokenexpires');
        let refreshTOk = localStorage.getItem('refresh_token');
        if(parseInt(tokenExpires) < new Date().getTime()){
            refreshTokenGen(refreshTOk).then(challenge => {
                if(challenge){
                    getPosts();
                    setIsLoading(true);
                }
            })
            
        } else {
            if(formParams === ''){
                getPosts();
            }
            setIsLoading(true);
        }
    }, []);
      useEffect(() =>{
            if(formParams){
                let filterQuery = queryParams(formParams);
                setHasMore(false);
                setItems([]);
                setPagi(1);
                setIsFilter({
                    filterEnable:true,
                    filterData: filterQuery
                });
            }
      }, [formParams]);
      useEffect(() =>{
          if(isFilter?.filterEnable){
            fetchMoreData()
          }
    }, [isFilter])
    const getPosts = () => {
        const token = localStorage.getItem('token');
        const refreshToken = localStorage.getItem('refresh_token');
        if(token){
            // &sort=[{"orderBy":"date", "order":"DESC"}]
        axios.get(process.env.REACT_APP_API_URL + '/event?page=1&limit=10&sort=[{"orderBy":"date", "order":"DESC"}]', { headers: setAuthorizationToken(token) })
          .then((res) => {
            if (res?.statusCode === 401) {
                refreshTokenGen(refreshToken).then(challenge => {
                    if(challenge){
                        getPosts();    
                    }
                })
            } else if(res.data){
                if(!isFilter?.filterEnable){
                setItems(res.data?.data);
                dispatch(contentData(res.data.data))
                setHasMore(res.data.hasNextPage);
                setIsLoading(false);
                }   
            }
          })
          .catch((err) => {
            console.log(err);
            if (err.response?.status === 401) {
                refreshTokenGen(refreshToken)?.then(challenge => {
                    if(challenge){
                        getPosts();    
                    }
                })
            }
          });
        }
    }
    const fetchMoreData = (() => {
        //if (isLoading) return;
        setIsLoading(true);
        $is_loading_more = true;
        const token = localStorage.getItem('token');
        const refreshToken = localStorage.getItem('refresh_token');
        let ajaxUrl = '';
        if(isFilter?.filterEnable){
            ajaxUrl = `${process.env.REACT_APP_API_URL}/event?page=${pagi}&limit=10&sort=[{"orderBy":"date", "order":"DESC"}]&filters={${isFilter?.filterData}}`;
        } else {
            ajaxUrl = `${process.env.REACT_APP_API_URL}/event?page=${pagi}&limit=10&sort=[{"orderBy":"date", "order":"DESC"}]`

        }
        if(token){
        axios.get(`${ajaxUrl}`, { headers: setAuthorizationToken(token) })
          .then((res) => {
            if (res?.statusCode === 401) {
                refreshTokenGen(refreshToken).then(challenge => {
                    if(challenge){
                        fetchMoreData();    
                    }
                })
            } else if(res.data){
                $is_loading_more = false;
                setPagi((prevPagi) => prevPagi + 1);
                setHasMore(res.data.hasNextPage);
                setItems((prevItems) => [...prevItems, ...res.data?.data]);
                //dispatch(contentData(res.data.data))
                setIsLoading(false);
            }
          })
        .catch((err) => {
                console.log(err)
        });
        }
    });
     const handleScroll = (scrollValues, prevScrollValues) => {
        const {scrollTop, clientHeight, scrollHeight} = scrollValues;
        if (clientHeight + scrollTop >= scrollHeight && hasMore) {
            if (!$is_loading_more) {
                fetchMoreData();
            }
        }
     }
    return (
        <>
        <Header/>
        <div className="breadCrumbSec">
            <div className="container siteContainer">
                  <div className='row'>
                    <div className='col-12'>
                        <ul className="resetList breadCrumbList">
                            <li><span>Case content</span></li>
                        </ul>
                    </div>
                  </div>
            </div>
        </div>
        <div className='pageDataSec'>
          <div className='container siteContainer'>
                <div className='row'>
                    <div className='col-4 sideBarCol'>
                        <div className='sideBarInnerCnt'>
                            <SearchForm />
                        </div>
                    </div>
                    <div className='col-8 detailCol'>
                    <div className='detailCntInner downloadSec'>
                        <Scrollbar  onScroll = {(scrollValues,  prevScrollValues) => handleScroll(scrollValues, prevScrollValues)}  style={{ height: `calc(${winHeight}px - ${calcHeight}px)`, width: '100%'}}   >
                          <div className='caseCntList'>
                              <div className='row headerrow'>
                                  <div className='col dateCol'>
                                      <p>Date</p>
                                  </div>
                                  <div className='col titleCol'>
                                      <p>Titre</p>
                                  </div>
                                  <div className='col previewCol'>
                                      <p>Prévisualiser</p>
                                  </div>
                                  <div className='col downloadCol'>
                                    <p>Télécharger tout</p>
                                  </div>
                                  <div className='col keyCol'>
                                    
                                  </div>
                              </div>
                     {items && items?.map((item, index) => (
                        <div className='row eachColCnt' key={index}>
                                  <div className='col dateCol eachCol'>
                                  {item.locked &&
                                      <p>{eventDateConvert(item?.date)}</p>
                                  }
                                  {!item.locked &&
                                      <p><Link to={`/case-content/${item.id}`} className="pageLink">{eventDateConvert(item?.date)}</Link></p>
                                  }
                                  </div>
                                  <div className='col titleCol eachCol'>
                                      {item.locked &&
                                            <p>{item?.title}</p>
                                      }
                                      {!item.locked &&
                                         <p><Link to={`/case-content/${item.id}`} className="pageLink">{item?.title}</Link></p>
                                      }
                                  </div>
                                  <div className='col previewCol eachCol'>
                                      <p>
                                        <button type='button' className='btn btn-img' onClick={(e) => previewModal(e, item?.title, item?.preview)}>
                                            <img src={item?.preview} className="img-fluid" alt="img" />
                                        </button>
                                    </p>
                                  </div>
                                  <div className='col downloadCol eachCol'>
                                        <p><button type="button" disabled={item.locked} onClick={(e) => getFullMedia(e, item?.id, item.title)} className="donwloadLink"><i className="iconmoon icon-filedownload downloadfileIcon"></i></button></p>
                                  </div>
                                  <div className='col keyCol eachCol'>
                                        {item.locked &&
                                            <p><i className="iconmoon icon-iconKey keyIcon"></i></p>
                                        }
                                </div>
                              </div>
                               ))}
                                {isLoading &&
                                    <div className='col-12'>
                                         <div className="spinner"></div>
                                    </div>
                                }
                                 {(items?.length <= 0 && !isLoading) &&
                                            <>
                                                <div className='row'>
                                                    <div className='col'>
                                                        <p className='text-center nodataText'>Aucun événement disponible</p>
                                                    </div>
                                                </div>
                                            </>
                                          }
                          </div>
                          </Scrollbar>
                          <div className="progress">
                                <div className="color"></div>
                           </div>
                        </div>
                    </div>
                </div>
              </div>
          </div>
          <PreviewModal modalImgUrl={modalImg}/>

        <Footer/>
        </>
    );
  }
export default CaseContentPage;