import { useEffect, useState }  from 'react';
import axios from 'axios';
import { setAuthorizationToken } from '../../common/utils';
import inMemoryJWT from '../../../inMemoryJwt';
import department from '../../common/searchForm/department.json';
import town from '../../common/searchForm/town.json';
import {getStateData} from '../../common/utils/index';
import { TabbedForm,Title, useRecordContext,SingleFieldList,ChipField,useRefresh,useNotify,useUpdate, Labeled,Input,SelectArrayInput,ReferenceArrayInput,AutocompleteArrayInput,Link, List, Edit, ShowButton, Show, BooleanInput,BooleanField, SimpleShowLayout, ImageField, useGetList, ArrayField, ReferenceInput, SelectInput, ReferenceManyField, FileInput, FileField, Datagrid, Button, ArrayInput, SimpleFormIterator, NumberInput, TextField, DateField, Create, SimpleForm, TextInput, DateInput, required, EditButton, AutocompleteInput, combineDataProviders } from 'react-admin';
import { useWatch } from "react-hook-form";
import MediaBluetoothOffIcon from '@mui/icons-material/MediaBluetoothOff';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

const createDesign = () => {
    let winLocation =  window.location.href;
    let getArray  = winLocation.split('/');
    let currentId = getArray[getArray.length - 1];
    return currentId;
}

const toChoices = (items) => items?.map((item) => ({ id: item.value, name: item.label }));
const SubThemeEditInput = () => {
    const { data: choices, isLoading: isLoadingChoices } = useGetList('theme');
    const themeId = useWatch({ name: 'theme' });
    return (
        <AutocompleteInput label="Sous-thème" 
            choices={(themeId && choices) ?  choices.find(x => x.id === themeId.id)?.children : [{id:"", title:""}]}
            source="subtheme.id"
            optionText="title"
            readOnly={themeId ? false : true}
        />
    );
};
const SubThemeCreateInput = () => {
    const { data: choices, isLoading: isLoadingChoices } = useGetList('theme');
    const themeId = useWatch({ name: 'theme' });
    return (
        <AutocompleteInput label="Sous-thème" defaultValue={""}
            choices={(themeId && choices) ?  choices.find(x => x.id === themeId).children : []}
            source="subtheme"
            optionText="title"
            readOnly={themeId ? false : true}
        />
    );
};
const cityTheme = (department, town) => {
    let villeList = town[department];
    return villeList?.map((item) => ({ id: item.value, name: item.label }))
}
const TownInput = () => {
    const departmentId = useWatch({ name: "dpt" });
    return <AutocompleteInput disabled={departmentId? false : true} source="town" optionText="name" choices={departmentId ? cityTheme(departmentId, town) : []} />;
}
const TownEditInput = () => {
    const departmentId = useWatch({ name: "dpt" });
return <AutocompleteInput disabled={departmentId? false : true} source="town" optionText="name" choices={departmentId ? cityTheme(departmentId, town) : []} />;
}

export const eventCreate = () => (
    <Create redirect="list">
        <SimpleForm >
        <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
            <TextInput source="title" multiline={true} label="Title"  validate={[required()]} sx={{ width: '100%' }} />
        </Grid>
        <Grid item xs={6}>
            <DateInput label="Publication" source="date" defaultValue={new Date()} validate={[required()]} sx={{ width: '100%' }}  />
        </Grid>
        <Grid item xs={6}>
            <BooleanInput label="Locked" source="locked"  defaultValue={false} validate={[required()]}/>
        </Grid>
        <Grid item xs={6}>
            <ReferenceInput label="Thème" source="theme"  reference="theme"> 
                    <AutocompleteInput source="id" optionText="title" validate={required()}/>
            </ReferenceInput>
        </Grid>
        <Grid item xs={6}>
            <SubThemeCreateInput/>
        </Grid>
        <Grid item xs={6}>
            <AutocompleteInput source="dpt"  autoComplete={true} label="Department" choices={toChoices(department)} />
        </Grid>
        <Grid item xs={6}>
            <TownInput />
        </Grid>
        <Grid item xs={6}>
            <ReferenceInput label="Concessionnaire" source="dealer" reference="users"> 
                    <AutocompleteInput source="id" defaultValue={""} optionText="lastName" />
            </ReferenceInput>
        </Grid>
        <Grid item xs={6}>
            <ReferenceInput label="Produits/services" source="service" reference="service"> 
                    <AutocompleteInput source="id" defaultValue={""} optionText="title"  />
            </ReferenceInput>
        </Grid>
        <Grid item xs={6}>
            <ReferenceInput label="Mots-clés" source="tags" reference="tag">
                    <AutocompleteArrayInput source="id" optionText="title" />
            </ReferenceInput>
        </Grid>
        <Grid item xs={12}>
            <FileInput source="preview"  validate={[required()]} >  
                        <FileField source="src" title="title" />
            </FileInput>
        </Grid>
    </Grid>
        </Box>
        </SimpleForm>
    </Create>
)

export const eventEdit = () => (
    <Edit title={<EventShowTitle />}>
        <SimpleForm >
        <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
           <TextInput disabled label="Id" source="id" sx={{ width: '100%' }}/>
           </Grid>
           <Grid item xs={6}>
            <TextInput source="title" validate={required()} sx={{ width: '100%' }} />
            </Grid>
            <Grid item xs={6}>
                <DateInput label="Publication" source="date" validate={required()} sx={{ width: '100%' }} />
            </Grid>
            <Grid item xs={6}>
                <BooleanInput label="Locked" source="locked"  validate={[required()]}/>
            </Grid>
            <Grid item xs={6}>
            <ReferenceInput label="Thème" source="theme.id" reference="theme"> 
                    <AutocompleteInput source="id" optionText="title" />
            </ReferenceInput>
            </Grid>
            <Grid item xs={6}>
                <SubThemeEditInput/>
            </Grid>
            <Grid item xs={6}>
                <AutocompleteInput source="dpt" label="Department" choices={toChoices(department)} />
            </Grid>
            <Grid item xs={6}>
                <TownEditInput />
            </Grid>
            <Grid item xs={6}>
            <ReferenceInput label="Concessionnaire" source="dealer.id" reference="users"> 
                    <AutocompleteInput source="id"  optionText="lastName"  />
            </ReferenceInput>  
            </Grid> 
            <Grid item xs={6}>
            <ReferenceInput label="Produits/services" source="service.id" reference="service"> 
                    <AutocompleteInput source="id"  optionText="title"  />
            </ReferenceInput>
            </Grid>
            <Grid item xs={6}>
                <ReferenceArrayInput reference="tag"  source="tags">
                        <AutocompleteArrayInput source="id" optionText="title" parse={(value) => value && value?.map((v) => ({ id: v }))}  format={(value) => value && value?.map((v) => v.id)} />
                </ReferenceArrayInput>
            </Grid>
            <Grid item xs={12}>
            <FileInput source="preview"  validate={[required()]} >
                        <FileField source="src" title="preview" />
                </FileInput>
            </Grid>
            </Grid>
            </Box>
        </SimpleForm>
    </Edit>
);
const EventShowTitle = () => {
    const record = useRecordContext();
    return <span>Event / {record ? `${record.title}` : ''}</span>;
};

export const eventShow = () => (
    <Show title={<EventShowTitle />}>
        <SimpleShowLayout>
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Labeled label="Title">
                             <TextField source="title" label="Title"  />
                         </Labeled>
                    </Grid>
                    <Grid item xs={6}>
                         <Labeled label="Publication">
                              <DateField source="date" locales="fr-FR" label="Publication" />
                         </Labeled>
                    </Grid>
                    <Grid item xs={6}>
                         <Labeled label="Locked">
                            <BooleanField source="locked" TrueIcon={LockIcon} FalseIcon={LockOpenIcon} valueLabelTrue="true" valueLabelFalse="false" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={6}>
                        <Labeled label="Thème">
                            <TextField source="theme.title" label="Thème" />
                         </Labeled>
                    </Grid>
                    <Grid item xs={6}>
                        <Labeled label="Sous-thème">
                            <TextField source="subtheme.title" label="Sous-thème" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={6}>
                        <Labeled label="Département Id">
                            <TextField source="dpt" label="Département Id" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={6}>
                        <Labeled label="Ville">
                            <TextField source="town" label="Ville" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={6}>
                        <Labeled label="Concessionnaire">
                             <TextField source="dealer.lastName" label="Concessionnaire" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={6}>
                        <Labeled label="Produits/services">
                            <TextField source="service.title" label="Produits/services" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={6}>
                        <Labeled label="Mots-clés">
                            <ArrayField source="tags" label="Mots-clés">
                                <SingleFieldList linkType={false}>
                                    <ChipField source="title" />
                                </SingleFieldList>
                            </ArrayField>
                        </Labeled>
                    </Grid>
                    <Grid item xs={6}>
                        <Labeled label="Preview">
                             <ImageField source="preview" title="title" />
                         </Labeled>
                    </Grid>
            </Grid>
        </Box>
            </SimpleShowLayout>
    </Show>
);
const MediaListButton = () => {
    const media = useRecordContext();
    return (
        <Button
            component={Link}
            to={`./${media?.id}/media`}
            startIcon={<MediaBluetoothOffIcon />}
            label='Media'
        >
            MediA
        </Button>
    );
};
const LockEdit = props => {
    console.log(props);

    const { locked, id, type } = props.record;
    const [showSubmit, setSubmitVisibility] = useState(false);
    const [position, setPosition] = useState("");

    const onFocusHandler = () => setSubmitVisibility(true);
    const onBlurHandler = () => {
        setSubmitVisibility(false);
        onClickHandler();
    };
    const onClickHandler = () => {
        const data = { locked, event_id: id, position, type};
        props.updatePosition(data)
    };

    const onChangeHandler = e => setPosition(e.target.value);

  return <Button
      id={`position-${props.record.id}`}
      defaultValue={props.record.locked}
      InputLabelProps={{
      }}
      onFocus={onFocusHandler}
      onBlur={onBlurHandler}
      onChange={onChangeHandler}
  >

 </Button>
}

export const EventList = props => {
    const refresh = useRefresh();
    const notify = useNotify();
    const [update] = useUpdate("locked", "");

    const updatePosition = data => {
        console.log(data);
        update(
            {
                payload: { data: { ...data } }
            },
            {
                onSuccess: () => {
                    refresh();
                    notify("Position Updated", 2000);
                }
            }
        );
    };
    return(
         <List >
            <Datagrid >
                <TextField source="id"  label="ID" sortable={false}/>
                <TextField source="title" label="Title" sortable={false} />
                <DateField source="date" locales="fr-FR" label="Publication" />
                <ImageField source="preview"  label="preview" sortable={false} />
                <BooleanField source="locked" TrueIcon={LockIcon} FalseIcon={LockOpenIcon}  valueLabelTrue="true" valueLabelFalse="false" sortable={false} />
                {/* <LockEdit source="locked" updatePosition={updatePosition}/> */}
                <EditButton />
                <MediaListButton />
                <ShowButton />
            </Datagrid>
        </List>
    )
};