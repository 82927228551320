import * as React from 'react';
import axios from 'axios';
import { setAuthorizationToken } from '../../common/utils';
import inMemoryJWT from '../../../inMemoryJwt';
import { TabbedForm, List, Edit, ShowButton, Show, SimpleShowLayout,Labeled,useRecordContext,Link, ArrayField, ReferenceManyField, FileInput, FileField, Datagrid, Button, ArrayInput, SimpleFormIterator, NumberInput, TextField, DateField, BooleanField, Create, SimpleForm, TextInput, DateInput, required, EditButton } from 'react-admin';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import MediaBluetoothOffIcon from '@mui/icons-material/MediaBluetoothOff';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';

export const PostCreate = () => (
    <Create redirect="list">
        <SimpleForm > 
            <TextInput source="title" multiline={true} label="Title" sx={{width:'50%'}}  validate={[required()]} />
            <DateInput label="Publication" source="date" sx={{width:'50%'}} defaultValue={new Date()} />
        </SimpleForm>
    </Create>
);
const PostShowTitle = () => {
    const record = useRecordContext();
    return <span>{record ? `${record.title}` : ''}</span>;
};
export const PostEdit = () => (
    <Edit title={<PostShowTitle />}>
        <SimpleForm redirect="list">
                <Box sx={{ flexGrow: 1, width:'100%' }}>
                       <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextInput disabled label="Id" sx={{width:'100%'}} source="id" />
                            </Grid>
                            <Grid item xs={6}>
                                <TextInput source="title" sx={{width:'100%'}} validate={required()} />
                            </Grid>
                            <Grid item xs={6}>
                                <DateInput label="Publication" sx={{width:'100%'}} source="date" />
                            </Grid>
                        </Grid>
                    </Box>
        </SimpleForm>
    </Edit>
);

export const PostShow = () => (
    <Show title={<PostShowTitle />}>
        <SimpleShowLayout>
              <Box sx={{ flexGrow: 1 }}>
                       <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Labeled label="Title">
                                        <TextField source="title" />
                                </Labeled>
                            </Grid>
                            <Grid item xs={6}>
                                <Labeled label="Date">
                                     <DateField source="date" locales="fr-FR" label="Date" />
                                </Labeled>
                            </Grid>
                        </Grid>
                    </Box>
            </SimpleShowLayout>
    </Show>
);
const ModelListButton = () => {
    const editions = useRecordContext();
    return (
        <Button
            component={Link}
            to={`./${editions?.id}/models`}
            startIcon={<BusinessCenterIcon />}
            label='Models'
        >
            Models
        </Button>
    );
};

const ProjectListButton = () => {
    const editions = useRecordContext();
    return (
        <Button
            component={Link}
            to={`./${editions?.id}/projects`}
            startIcon={<CorporateFareIcon />}
            label='Projects'
        >
            Projects
        </Button>
    );
};

export const PostList = () => (
    <List>
        <Datagrid>
            <TextField source="id"  label="ID"/>
            <TextField source="title" label="Title" />
            <DateField source="date" label="Publication" />
            <EditButton />
            <ModelListButton />
            <ProjectListButton />
            <ShowButton />
        </Datagrid>
    </List>
);