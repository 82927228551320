import React from 'react'
import {NavLink, Link} from 'react-router-dom'
import { useLogout , useGetIdentity, usePermissions} from 'react-admin';

function Header() {
    const logout = useLogout();
    const userIdentity = useGetIdentity();
    const logoutHandle = () => logout();
    const { permissions } = usePermissions()
    return (
        <>
            <header className="header">
                <div className="container siteContainer">
                    <div className="row">
                        <div className="col-3">
                            <div className="logContent">
                                <a href="/" className="logo">
                                    Plateforme de gestion
                                    Farm Forum CASE IH
                                </a>
                            </div>
                        </div>
                        <div className="col-8 headMenuCol">
                            <div className="headerMenuInnerCOl">
                                <div className="menuContent">
                                    <ul className="resetList menuList">
                                        <li className="eventmenu"><NavLink  to="/" className="nav-link" activeclassname="active"><span>Bibliothèque de contenus</span></NavLink></li>
                                        <li><NavLink  to="/farm-forum" activeclassname="active" className="nav-link"><span>FARM FORUM</span></NavLink ></li>
                                    </ul>
                                </div>
                                <div className='userDetail'>
                                <div className='userDetailCnt'>
                                    <p className='userEmail m-0'>{userIdentity?.data?.fullName}
                                        <span className='userEmailAdd'>{userIdentity?.data?.email}</span>
                                    </p>
                                </div>
                                <div className="logoutBtnCnt">
                                    <button className="btn logoutBtn" type="button" onClick={() => logoutHandle()}>
                                        <i className="iconmoon icon-icon_logout logDetails"></i>
                                    </button>
                                </div>
                                {permissions === 'Admin' ?
                                <>
                                    <div className="adminPageBtn">
                                        <NavLink to="/admin" activeclassname="active" className="nav-link"><span>Admin</span></NavLink >
                                    </div>
                                </> : null}
                                </div>
                            </div>
                            <img src="/images/startImg.png" className="img-fluid starImg"  />
                        </div>
                    </div>
                </div>
            </header>

        </>
    );
  }
export default Header;
