import * as React from 'react';
import { List, ShowButton, Show,Edit, SimpleShowLayout,PasswordInput,email,Labeled,useRecordContext, ArrayField, ReferenceManyField, FileInput, FileField, Datagrid, Button, ArrayInput, SimpleFormIterator, NumberInput, TextField, DateField, BooleanField, Create, SimpleForm, TextInput, DateInput, required, EditButton } from 'react-admin';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

const validateEmail = email();
const equalToPassword = (value, allValues) => {
    if (value !== allValues.password) {
        return 'The two passwords must match';
    }
}
const UserShowTitleNormal = () => {
    const record = useRecordContext();
    return <span>User / {record ? `${record.username}` : ''}</span>;
};
export const usersList = () => (
    <List >
    <Datagrid>
        <TextField source="email" />
        <TextField source="username" />
        <TextField source="orders" />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <EditButton/>
        <ShowButton/>
    </Datagrid>
</List>
)

export const usersShow = () => (
    <Show title={<UserShowTitleNormal />}>
             <SimpleShowLayout>
                    <Box sx={{ flexGrow: 1 }}>
                       <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Labeled label="Email">
                                    <TextField source="email" label="Email" />
                                </Labeled>
                            </Grid>
                            <Grid item xs={6}>
                                <Labeled label="User Name">
                                       <TextField source="username" label="UserName" />
                                </Labeled>
                            </Grid>
                            <Grid item xs={6}>
                                <Labeled label="Orders">
                                <   FileField source="orders" title="Orders" />
                                </Labeled>
                            </Grid>
                        </Grid>
                    </Box>
                </SimpleShowLayout>
        </Show>
)

export const usersEdit = () => (
<Edit title={<UserShowTitleNormal />}>
        <SimpleForm>
        <Box sx={{ flexGrow: 1,  width: '100%' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextInput source="email" sx={{ width: '100%' }}  validate={validateEmail} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput source="username" sx={{ width: '100%' }} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput source="orders" sx={{ width: '100%' }}  />
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput source="firstName" sx={{ width: '100%' }} validate={[required()]} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput source="lastName" sx={{ width: '100%' }} validate={[required()]}/>
                        </Grid>
                        <Grid item xs={6}>
                                <PasswordInput source="password" sx={{ width: '100%' }} />
                        </Grid>
                        <Grid item xs={6}>
                            <PasswordInput source="confirm_password" validate={equalToPassword} sx={{ width: '100%' }} />
                        </Grid>
                    </Grid>
            </Box>
        <ShowButton/>
   </SimpleForm>
   </Edit>
)

export const usersCreate = () => (
    <Create redirect="list">
        <SimpleForm>
            <Box sx={{ flexGrow: 1,  width: '100%' }}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                            <TextInput source="email" sx={{ width: '100%' }} label="Email" validate={validateEmail} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput source="username" sx={{ width: '100%' }} label="User name"/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput source="orders" sx={{ width: '100%' }} label="Orders"/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput source="role[name]" readOnly sx={{ width: '100%' }} defaultValue={'user'} label="User Role"/>
                    </Grid>
                    <Grid item xs={6}>
                            <TextInput source="firstName" sx={{ width: '100%' }} validate={[required()]}/>
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput source="lastName" sx={{ width: '100%' }} validate={[required()]}/>
                        </Grid>
                    <Grid item xs={6}>
                        <PasswordInput source="password" sx={{ width: '100%' }} label="Password"/>
                    </Grid>
                    <Grid item xs={6}>
                        <PasswordInput source="confirm_password" label="Confirm Password" sx={{ width: '100%' }} validate={equalToPassword} />
                    </Grid>
                </Grid>
            </Box>
       </SimpleForm>
    </Create>
)