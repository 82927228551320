function Footer() {
    return (
        <>
            <footer className="footer_Sec">
                <div className="container siteContainer">
                    <p className="noMargin"><a href="/mentions-legales" className="weblink">Consultez les mentions légales.</a></p>
                </div>
                <div className="downloadSecCnt">

                </div>

            </footer>
        </>
    );
  }
export default Footer;