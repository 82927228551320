import logo from "./logo.svg";
import "./App.css";
import React from "react";
import { Admin, CustomRoutes, ListGuesser, Resource, ShowGuesser, EditGuesser, usePermissions  } from "react-admin";
import {PostList, PostCreate, PostEdit, PostShow} from "./components/admin/posts/index";
import { BrowserRouter, Routes, Route, Redirect } from 'react-router-dom';
import { StoreFront } from "./storeFront.js";
import { StoreAdmin } from "./StoreAdmin.js";

function App() {
  return (
    <BrowserRouter>
        <Routes>
            <Route path="/admin/*" element={<StoreAdmin />} />
            <Route path="/*" element={<StoreFront />} />
        </Routes>
    </BrowserRouter>
  );
}

export default App;
