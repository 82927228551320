import * as actionTypes from './actionTypes';


export const contentFilter = () => {
    return {
      type: "CONTENT_SEARCH_FILTER",
    };
  };
export const contentData = (data) => {
  return {
      type: "CONTENT_DATA",
      contentList:data
  };
};
export const searchEnabled = () => {
  return {
      type: "SEARCH_ENABLED",
  };
};
export const villeData = (data) => {
  return {
      type: "VILLE_DATA",
      ville:data
  };
};

export const searchFormEle = (params, filterEnable) => {
  return {
    type: "SEARCH_FORM_DATA",
    formValues:params,
    filterData:filterEnable
};
}

