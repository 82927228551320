import { useState, useEffect } from 'react';
import { Modal} from 'bootstrap';
import ReactPlayer from 'react-player'
function VideoModal(props) {
    const [modalAutoplay, setVideoPlay] = useState(true);
    let myVideoModalEl = document.getElementById('videoModalCenter')
        myVideoModalEl?.addEventListener('hidden.bs.modal', function (event) {
            setVideoPlay(false);
        })
        useEffect(() => {
            setVideoPlay(true);
        });  
    return (
        <>
        <div className="modal fade" id="videoModalCenter" tabIndex="-1" role="dialog" aria-labelledby="videoModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
                <div className="modal-content mx-auto border-0 bg-transparent">
                    <div className="modal-header w-100 p-10 border-0">
                        <h5 className="modal-title previeMoaltitle" id="previeMoaltitle">{props.modalVideoUrl.title}</h5>
                        <button type="button" className="close modal-close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body w-100 p-0">
                            <div className="modalImageCnt">
                            <ReactPlayer width="100%"
                                                                className="mediaPlayer" controls={true}
                                                                url={props.modalVideoUrl.videoUrl}
                                                                 playing={modalAutoplay}
                                                                 progressInterval={200}
                                                                 onSeek={(e)=>console.log('onSeek ======= ', e)}
                                                                 config={{
                                                                     youtube: {
                                                                         playerVars: {rel: 0, showinfo: 0, modestbranding:0 }
                                                                     },
                                                                     vimeo: {
                                                                         playerOptions: {
                                                                             playsinline: 1
                                                                         }
                                                                     }
                                                                 }}
                                                    > </ReactPlayer>
                                
                            </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
  }
export default VideoModal;