import * as React from 'react';
import { TabbedForm, List,Edit, UrlField,Link,useRecordContext,useGetRecordId,useRedirect,TopToolbar, SelectInput, Show, SimpleShowLayout,ReferenceInput,AutocompleteInput, SelectField, ArrayField, ReferenceManyField, FileInput, FileField, Datagrid, Button, ArrayInput, SimpleFormIterator, NumberInput, TextField, DateField, BooleanField, Create, SimpleForm, TextInput, DateInput, required, EditButton, ShowButton, Labeled } from 'react-admin';
import { setAuthorizationToken } from '../../common/utils';
import inMemoryJWT from '../../../inMemoryJwt';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import MediaBluetoothOffIcon from '@mui/icons-material/MediaBluetoothOff';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

const getIdFunction = () => {
    let winLocation = window.location.href;
    let getProjectId = winLocation.split('/');
    return parseInt(getProjectId[getProjectId.length - 1]);
}

export const mediaCreate = () => (
    <Create >
        <SimpleForm>
        <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                        <Grid item xs={6}>
                             <TextInput source="title" multiline={true} label="Title" sx={{width:'100%'}}  validate={[required()]} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextInput source="description" multiline={true} label="Description " sx={{width:'100%'}}  validate={[required()]} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextInput source="event" label="Event" sx={{width:'100%'}} disabled defaultValue={getIdFunction()} />
                            </Grid>
                            <Grid item xs={6}>
                                <SelectInput source="type" choices={[{ id: 'video', name: 'video' },  { id: 'file', name: 'file' }, { id: 'image', name: 'image' },
            
        ]} validate={required()}/>
                            </Grid>
                            <Grid item xs={12}>
                                <FileInput source="file"  validate={[required()]} >
                                    <FileField source="src" title="title" />
                                </FileInput> 
                            </Grid>
                        </Grid>
                </Box>
        </SimpleForm>
    </Create>
);  
export const mediaEdit = () => (
    <Edit title={<MediaSepListTitle />}>
        <SimpleForm>
        <Box sx={{ flexGrow: 1, width:'100%' }}>
                <Grid container spacing={2}>
                        <Grid item xs={6}>
                              <TextInput sx={{width:'100%'}} source="title" multiline={true} label="Title"  validate={[required()]}  />
                        </Grid>
                        <Grid item xs={6}>
                                 <TextInput sx={{width:'100%'}} source="description" label="Description" validate={[required()]} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput source="event.id" label="Event Id" readOnly/>
                        </Grid>
                        <Grid item xs={6}>
            <SelectInput source="type" validate={required()} choices={[{ id: 'video', name: 'video' },  { id: 'file', name: 'file' }, { id: 'image', name: 'image' },
    
]} />       
                        </Grid>
                        <Grid item xs={12}>
 <FileInput source="file"  validate={[required()]} >
                        <FileField source="src" title="title" />
                </FileInput> 
                    </Grid>
                </Grid>
            </Box>
        </SimpleForm>
    </Edit>
);
export const mediaShow = () => (
    <Show title={<MediaSepListTitle />}>
        <SimpleShowLayout>
            <Box sx={{ flexGrow: 1, width:'100%' }}>
                <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Labeled label="ID">
                                <TextField source="id"  label="ID"/>
                            </Labeled>
                        </Grid>
                        <Grid item xs={6}>
                            <Labeled label="Title">
                                <TextField source="title" label="Title" />
                            </Labeled>
                        </Grid>
                        <Grid item xs={6}>
                           <Labeled label="Event">
                               <TextField source="event.title" label="Event"/>
                            </Labeled>
                        </Grid>
                        <Grid item xs={6}>
                             <Labeled label="Type">
                                 <TextField source="type" label="Type"/>
                            </Labeled>
                        </Grid>
                        <Grid item xs={6}>
                             <Labeled label="File">
                                    <FileField source="file" title="title" />
                            </Labeled>
                        </Grid>
                    </Grid>
                </Box>
        </SimpleShowLayout>
    </Show>
);

export const mediaList = () => (
    <List hasCreate={false}>
        <Datagrid>
            <TextField source="id" label="Id" />
            <TextField source="title" label="Title" />
            <TextField source="event.title" label="Event"/>
            <TextField source="type" label="Type"/>
            <FileField source="file" title="title" />
            <EditButton />
            <ShowButton />
        </Datagrid>
    </List>
);
const MediaSepListTitle = () => {
    const record = useRecordContext();
    return <span>Media / {record ? `${record.title}` : ''}</span>;
};
const MediaListTitle = () => {
    const record = useGetRecordId();
    return <span>Event / {record ? `${record} / Media` : ''}</span>;
};
const MediaCreateTitle = () => {
    const record = useGetRecordId();
    return <span>Event / {record ? `${record} / Créer Media` : ''}</span>;
};

export const MediaNestedList = () => {
    const { id } = useParams();
    return (
        <List resource={`event/${id}/media`} hasCreate={true} title={<MediaListTitle />}>
             <Datagrid>
                <TextField source="id" label="Id" />
                <TextField source="title" label="Title" />
                <TextField source="type" label="Type"/>
                <FileField source="file" title="title" />
                <EditButton />
                <ShowButton />
            </Datagrid>
        </List>
    );
};
const MediaShowButton = () => { 
    const { id, mediaId } = useParams();
    return(
        <ShowButton component={Link}
        to={`/admin/event/${id}/media/${mediaId}/show`} label="Afficher" /> 
    )
}
const MediaEditActions = () => (
    <TopToolbar>
        <MediaShowButton/>
    </TopToolbar>
);
export const MediaNestedEdit= () => {
     const { id, mediaId } = useParams();
    return (
        <Edit resource={`media`} id={mediaId} redirect={`/admin/event/${id}/media`} actions={<MediaEditActions />} title={<MediaShowTitle />}>
        <SimpleForm>
        <Box sx={{ flexGrow: 1, width:'100%' }}>
                <Grid container spacing={2}>
                        <Grid item xs={6}>
                             <TextInput  source="title" multiline={true} label="Title" sx={{width:'100%'}} validate={[required()]}  />
                        </Grid>
                        <Grid item xs={6}>
                             <TextInput source="description" label="Description" sx={{width:'100%'}} validate={[required()]} />
                        </Grid>
                        <Grid item xs={6}>
                            <ReferenceInput label="Event" source="event.id" reference="event"> 
                                        <AutocompleteInput label="Event" source="id" optionText="title" sx={{width:'100%'}} readOnly />
                            </ReferenceInput>
                        </Grid>
                        <Grid item xs={6}>
            <SelectInput source="type" validate={required()} choices={[{ id: 'video', name: 'video' },  { id: 'file', name: 'file' }, { id: 'image', name: 'image' },
    
]} />
                            </Grid>     
                            <Grid item xs={12}>
   <FileInput source="file"  validate={[required()]} >
                        <FileField source="src" title="title" />
                </FileInput> 
                </Grid>
                </Grid>
                </Box>
           
        </SimpleForm>
    </Edit>
    );
};
export const MediaNestedCreate = () => {
    const { id} = useParams();
    return (
    <Create resource={`media`} redirect={`/admin/event/${id}/media`} title={<MediaCreateTitle />}>
        <SimpleForm>
        <Box sx={{ flexGrow: 1, width:'100%' }}>
                <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextInput source="title" multiline={true} label="Title"  sx={{width:'100%'}} validate={[required()]} />
                        </Grid>
                        <Grid item xs={6}>
                              <TextInput source="description" multiline={true} label="Description "   sx={{width:'100%'}}  validate={[required()]} />
                         </Grid>
                         <Grid item xs={6}>
                            <ReferenceInput label="Event" source="event" reference="event"> 
                                <AutocompleteInput source="id" optionText="title" readOnly defaultValue={id}/>
                            </ReferenceInput>
                        </Grid>
                        <Grid item xs={6}>
                                    <SelectInput source="type" choices={[{ id: 'video', name: 'video' },  { id: 'file', name: 'file' }, { id: 'image', name: 'image' }]} validate={required()}/>
                            </Grid>
                            <Grid item xs={12}>
                    <FileInput source="file"  validate={[required()]} >
                        <FileField source="src" title="title" />
                    </FileInput> 
                    </Grid>
                </Grid>
            </Box>    
        </SimpleForm>
    </Create>
   );
};  
const MediaEditButton = () => { 
    const { id, mediaId } = useParams();
    return(
        <EditButton component={Link}
        to={`/admin/event/${id}/media/${mediaId}`} label="Edit" /> 
    )
}
const MediaShowActions = () => (
    <TopToolbar>
        <MediaEditButton/>
    </TopToolbar>
);

const MediaShowTitle = () => {
    const record = useRecordContext();
    return <span>Event / {record ? `${record.event?.title} / Media / ${record.title}` : ''}</span>;
};
export const MediaNestedShow = () => {
     const { id, mediaId } = useParams();
    
     return (
        <Show resource={`media`} id={mediaId}  actions={<MediaShowActions />} title={<MediaShowTitle />}>
            <SimpleShowLayout>
            <Box sx={{ flexGrow: 1, width:'100%' }}>
                <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Labeled label="Id">
                                <TextField source="id"  label="ID"/>
                            </Labeled>
                        </Grid>
                        <Grid item xs={6}>
                             <Labeled label="Title">
                                   <TextField source="title" label="Title" />
                             </Labeled>
                        </Grid>
                        <Grid item xs={6}>
                              <Labeled label="Type">
                                    <TextField source="type" label="Type"/>
                                </Labeled>
                        </Grid>
                        <Grid item xs={6}>
                            <Labeled label="File">
                                <FileField source="file" title="title" />
                            </Labeled>
                        </Grid>
                    </Grid>
                </Box>
            </SimpleShowLayout>
         </Show>
        
    );
};

