import { useState } from 'react';
import * as Yup from "yup";
import {Modal} from 'bootstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import {setAuthorizationToken} from '../../common/utils/index';
import {refreshTokenGen} from '../../../inMemoryJwt.js'
import axios from 'axios';


function ValidationModifierModal(props) {
    const passDataToParent = props;
    const [fromError, setfromError] = useState('');
    const [correctionFiles, setCorrectionFiles] = useState([]);
    const correction = (e, a, files) => {
        setCorrectionFiles(files);
    };
    const removeBtn = (e, currentIndex, currentList) => {
        if (currentList === "correctionFileRemove") {
          let orginalArray = correctionFiles;
         
          const newSliceArray = orginalArray.filter((value, i) => {
            return i !== currentIndex;
          });
          setCorrectionFiles(newSliceArray);
        } 
      };
    const validationModifyFormEle = (data, id, index, pagePost) =>{
        const token = localStorage.getItem('token');
        const refreshToken = localStorage.getItem('refresh_token');
        const currentValide = pagePost?.designs[index];
        const correctionData  = data.correction[0];
        const correctObj = { "correction": data.message,"correctionfile":correctionData, "validated":false, "hascorrection":true };
        const formData = new FormData();
        formData.append('correction', data.message);
        formData.append('correctionfile', correctionData);
        formData.append('validated', false);
        formData.append('hascorrection', true);
        if (token) {
            // console.log(correctObj);
            axios.patch(process.env.REACT_APP_API_URL + '/design/' + id, formData, { headers: setAuthorizationToken(token) })
            .then(response => {
                if (response.code === 401) {
                    refreshTokenGen(refreshToken).then(challenge => {
                        if(challenge){
                            validationModifyFormEle(data, id, pagePost);    
                        }
                    })
                } else {
                    setfromError(true);
                    setCorrectionFiles([]);
                    const timeoutId = setTimeout(() => {
                        document.querySelectorAll(`.btn-disable-${id}`)[0]?.classList.add('disabled');
                        document.querySelectorAll(`.btn-disable-${id}`)[1]?.classList.add('disabled');
                        document.querySelectorAll('#modifierModalCenter .modal-close')[0]?.click();
                        clearTimeout(timeoutId);
                        setfromError('');
                    }, 1000);
                }
            })
            .catch(error => {
                if(error.response.status === 401){
                    refreshTokenGen(refreshToken).then(challenge => {
                        if(challenge){
                            validationModifyFormEle(data, id, pagePost);     
                        }
                    })
                }
                setfromError(false); 
            });
        } else {
            // console.log('else');
            // console.log(correctObj);
        }

    }
    return (
        <div className="modal fade" id="modifierModalCenter" tabIndex="-1" role="dialog" aria-labelledby="modifierModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title modifierModaltitle" id="modifierModaltitle">{props.modifierFormData.name}</h5>
                    <button type="button" className="close modal-close" data-bs-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                    </button>
                </div>
      <Formik
       initialValues={{
        correction: correctionFiles,
        message: '' 
    }}
    validationSchema={Yup.object().shape({
        message: Yup.string()
            .required(`ce dépôt est obligatoire`),
        correction: Yup.array().test(
            "fileFormat",
            "Seuls les fichiers Pdf, doc, docx, jpeg, jpg, png, mp4, mov, avi, zip sont autorisés",
            (value) => {
                if (value) {
                const supportedFormats = ['pdf', 'doc','docx', 'jpeg', 'jpg', 'png', 'mp4', 'mov', 'avi', 'zip'];
                    for(let j = 0; j < value.length; j++){
                        const fileName = value[j]?.name?.split(".").pop().toLowerCase();
                        if(!supportedFormats.includes(fileName)){
                            return false;
                        };
                    }
                }
                return true;
            }
            ),
    })}
    onSubmit={(values, actions) => {
        validationModifyFormEle(values, props.modifierFormData.id, props.modifierFormData.i, props.pagePost);
       actions.resetForm();
      }}
    >
      {({ handleSubmit, handleChange, handleBlur, values, setFieldValue, errors }) => (
        <div className="modal-body">
            <div className='modifierFormContainer'>
                    <Form onSubmit={handleSubmit}>
                    <div className='row'>
                        <div className='col-12 form-group'>
                            <h6 className='m-0 modifierHeading'>Déposer vos corrections ici</h6>
                        </div>
                    </div>
                    <div className='row uploadFilecol'>
                        <div className='col-12 form-group'>
                            <p>Correction File</p>
                            <div className="fileUploadCol">
                                    <div className="iconDownloadAdd" >
                                            <Field className="form-control custominputFile "
                                id="correctionFile"
                                name="correction1" 
                                type="file"
                                onChange={(event) => {
                                    const correctionNewFiles =
                                    event.target.files;
                                    let correctionMyFiles;
                                    if (correctionFiles.length) {
                                    correctionMyFiles = Array.from(correctionNewFiles);
                                    } else {
                                    correctionMyFiles =
                                        Array.from(correctionNewFiles);
                                    }
                                    setFieldValue(
                                        "correction",
                                        correctionMyFiles
                                    );
                                    correction(
                                    event,
                                    "avatar",
                                    correctionMyFiles
                                    );
                                }} />
                                            <label
                                                    htmlFor="correctionFile"
                                                    className="upoadloadIcon"
                                                >
                                                    <i className="icon-filedownload iconmoon"></i>
                                                </label>
                                                </div>
                                                <div className="uploadListCol">
                                                    <ul className="resetList filesList">
                                                        {[...correctionFiles].map((f, i) => (
                                                        <li key={i}>
                                                            {f.name}{" "}
                                                            <button
                                                            type="button"
                                                            className="removeItem filesRemove"
                                                            onClick={(e) => {
                                                                removeBtn(
                                                                e,
                                                                i,
                                                                "correctionFileRemove"
                                                                );
                                                            }}
                                                            >
                                                            <i className="icon-icon_close iconmoon closeIcon"></i>
                                                            </button>
                                                        </li>
                                                        ))}
                                                    </ul>
                                            
                                    </div>
                                <ErrorMessage name="message" component="div" className="errorMsg"/>
                            </div>
                         </div>   
                    </div>
                    <div className='row'>
                        <div className='col-12 form-group'>
                            <Field as="textarea" className="form-control message" name="message"  placeholder="Description" />
                            <ErrorMessage name="message" component="div" className="errorMsg"/>
                        </div>
                    </div>
                   
                        <div className="row">
                                <div className='col-12 form-group noMargin submitBtnCol'>
                                <p className='noMargin text-center'>
                                    <button type="submit" className="submitBtn">
                                        Envoyer
                                    </button>
                                </p>
                                {fromError &&
                            <p className="successMsg text-center alertTxt">Votre message a été envoyé avec succès</p>
                        }
                        {fromError === false &&
                            <p className="errorMsg text-center alertTxt">Oups, quelque chose n'allait pas</p>
                        }
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        )}
     </Formik>
     </div>
        </div>
        </div>
    )
}
export default ValidationModifierModal;