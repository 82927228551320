import { useState } from 'react';
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import {setAuthorizationToken} from '../../common/utils/index';
import {refreshTokenGen} from '../../../inMemoryJwt.js'
import axios from 'axios';
import { Modal} from 'bootstrap';

function SuggestedModal(props) {
    let {suggestedDetail} = props;
    const [fromError, setfromError] = useState('');
    const [propositionFiles, setpropositionFiles] = useState([]);
    const proposition = (e, a, files) => {
        setpropositionFiles(files);
    };
    const removeBtn = (e, currentIndex, currentList) => {
        if (currentList === "propositionFileRemove") {
          let orginalArray = propositionFiles;
         
          const newSliceArray = orginalArray.filter((value, i) => {
            return i !== currentIndex;
          });
          setpropositionFiles(newSliceArray);
        } 
      };
    const suggestedFormEle = (data, id, userEmail) =>{
        const token = localStorage.getItem('token');
        const refreshToken = localStorage.getItem('refresh_token');
        const propositionData  = data.proposition[0];
        const formData = new FormData();
        formData.append('user', userEmail);
        formData.append('message', data.message);
        formData.append('proposition', propositionData);
      
        if (token) {
            axios.post(process.env.REACT_APP_API_URL + '/editions/advice', formData, { headers: setAuthorizationToken(token) })
            .then(response => {
                // console.log(response);
                if (response.code === 401) {
                    refreshTokenGen(refreshToken).then(challenge => {
                        if(challenge){
                            suggestedFormEle(data, id);    
                        }
                    })
                } else {
                    setfromError(true);
                    setpropositionFiles([]);
                    const timeoutId = setTimeout(() => {
                        document.querySelectorAll('#suggestedModalCenter .modal-close')[0]?.click();
                        setfromError('');
                        clearTimeout(timeoutId);
                    }, 1000);
                }
            })
            .catch(error => {
                // console.log(error);
                if(error.response?.status === 401){
                    refreshTokenGen(refreshToken).then(challenge => {
                        if(challenge){
                            suggestedFormEle(data, id);    
                        }
                    })
                }
                setfromError(false);
            });
        }
    }
   return (
        <div className="modal fade" id="suggestedModalCenter" tabIndex="-1" role="dialog" aria-labelledby="suggestedModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title suggestModaltitle" id="suggestModaltitle">Proposition d’articles pour Farm Forum ou Farm Forum Digital</h5>
                    <button type="button" className="close modal-close" data-bs-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                    </button>
                </div>
      <Formik 
       initialValues={{
        proposition: propositionFiles,
        title: '',
        message: '' 
    }}
    validationSchema={Yup.object().shape({
        proposition: Yup.array().test(
            "fileFormat",
            "Seuls les fichiers Pdf, doc, docx, jpeg, jpg, png, mp4, mov, avi, zip sont autorisés",
            (value) => {
                if (value) {
                const supportedFormats = ['pdf', 'doc','docx', 'jpeg', 'jpg', 'png', 'mp4', 'mov', 'avi', 'zip'];
                    for(let j = 0; j < value.length; j++){
                        const fileName = value[j]?.name?.split(".").pop().toLowerCase();
                        if(!supportedFormats.includes(fileName)){
                            return false;
                        };
                    }
                }
                return true;
            }
            ),
        message: Yup.string()
        .required(`ce dépôt est obligatoire`),
    })}
    onSubmit={(values, actions) => {
            suggestedFormEle(values, suggestedDetail.id, suggestedDetail.userEmail);
            actions.resetForm();
        }}
    >
     {({ setFieldValue }) => (
        <div className="modal-body">
            <div className='suggestFormContainer'>
            <Form>
            <div className='row uploadFilecol'>
                        <div className='col-12 form-group'>
                            <div className='row'>
                                <div className='col-5'>
                                     <p>Proposition d'articles  File</p>
                             </div>
                            <div className='col-7'>
                            <div className="fileUploadCol">
                                    <div className="iconDownloadAdd" >
                                            <Field className="form-control custominputFile "
                                id="propositionFile"
                                name="proposition1" 
                                type="file"
                                onChange={(event) => {
                                    const propositionNewFiles =
                                    event.target.files;
                                    let propositionMyFiles;
                                    if (propositionFiles.length) {
                                    propositionMyFiles = Array.from(propositionNewFiles);
                                    } else {
                                    propositionMyFiles =
                                        Array.from(propositionNewFiles);
                                    }
                                    setFieldValue(
                                        "proposition",
                                        propositionMyFiles
                                    );
                                    proposition(
                                    event,
                                    "avatar",
                                    propositionMyFiles
                                    );
                                }} />
                                            <label
                                                    htmlFor="propositionFile"
                                                    className="upoadloadIcon"
                                                >
                                                    <i className="icon-filedownload iconmoon"></i>
                                                </label>
                                                </div>
                                                <div className="uploadListCol">
                                                    <ul className="resetList filesList">
                                                        {[...propositionFiles].map((f, i) => (
                                                        <li key={i}>
                                                            {f.name}{" "}
                                                            <button
                                                            type="button"
                                                            className="removeItem filesRemove"
                                                            onClick={(e) => {
                                                                removeBtn(
                                                                e,
                                                                i,
                                                                "propositionFileRemove"
                                                                );
                                                            }}
                                                            >
                                                            <i className="icon-icon_close iconmoon closeIcon"></i>
                                                            </button>
                                                        </li>
                                                        ))}
                                                    </ul>
                                            
                                    </div>
                               </div>
                               <ErrorMessage name="message" component="div" className="errorMsg"/>
                            </div>
                            </div>
                         </div>   
                    </div>
            <div className='row'>
                <div className='col-12 form-group'>
                    <Field as="textarea" className="form-control message" name="message"  placeholder="Description" />
                    <ErrorMessage name="message" component="div" className="errorMsg"/>
                </div>
            </div>
                <div className="row">
                        <div className='col-12 form-group noMargin submitBtnCol'>
                        <p className='noMargin text-center'>
                            <button type="submit" className="submitBtn">
                                Envoyer
                            </button>
                        </p>
                        {fromError &&
                            <p className="successMsg  text-center alertTxt">Votre message a été envoyé avec succès</p>
                        }
                        {fromError === false &&
                            <p className="errorMsg text-center alertTxt">Oups, quelque chose n'allait pas</p>
                        }
                    </div>
                </div>
            </Form>
            </div>
        </div>
        )}
     </Formik>
     </div>
     </div>
     </div>
    )
}
export default SuggestedModal;