import axios from 'axios';
import {setAuthorizationToken} from '../src/components/common/utils/index.js'
const inMemoryJWTManager = () => {
    let inMemoryJWT = null;
    let isRefreshing = null;
    let logoutEventName = 'ra-logout';
    let refreshEndpoint = '/auth/refresh';
    let refreshTimeOutId;

    const setLogoutEventName = name => logoutEventName = name;
    const setRefreshTokenEndpoint = endpoint => refreshEndpoint = endpoint;

    // This countdown feature is used to renew the JWT before it's no longer valid
    // in a way that is transparent to the user.
    // const refreshToken = (refreshToken, delay) => {
    //     getRefreshedToken(refreshToken)
    //     refreshTimeOutId = window.setTimeout(
    //         getRefreshedToken,
    //         delay * 1000 - 5000
    //     ); // Validity period of the token in seconds, minus 5 seconds
    // };

    const abordRefreshToken = () => {
        if (refreshTimeOutId) {
            window.clearTimeout(refreshTimeOutId);
        }
    };

    const waitForTokenRefresh = () => {
        if (!isRefreshing) {
            return Promise.resolve();
        }
        return isRefreshing.then(() => {
            isRefreshing = null;
            return true;
        });
    }

    // The method make a call to the refresh-token endpoint
    // If there is a valid cookie, the endpoint will set a fresh jwt in memory.
    const getRefreshedToken = () => {
        // console.log('getExporyToken')
        // const request = new Request(refreshEndpoint, {
        //     method: 'GET',
        //     headers: new Headers({ 'Content-Type': 'application/json' }),
        //     credentials: 'include',
        // });

        // isRefreshing = fetch(request)
        //     .then((response) => {
        //         if (response.status !== 200) {
        //             ereaseToken();
        //             global.console.log(
        //                 'Token renewal failure'
        //             );
        //             return { token: null };
        //         }
        //         return response.json();
        //     })
        //     .then(({ token, tokenExpiry }) => {
        //         if (token) {
        //             setToken(token, tokenExpiry);
        //             return true;
        //         }
        //         ereaseToken();
        //         return false;
        //     });

        // return isRefreshing;
    };
    //RefreshTOkengenerate
    const refreshTokenGen = (refreshToken) => {
        let refreshTokenMulti = localStorage.getItem('norefresh'); 
        if(refreshTokenMulti){
            localStorage.setItem('norefresh', false); 
            return new Promise((resolve, reject) => {
                axios.post(process.env.REACT_APP_API_URL + '/auth/refresh', void 0, {headers: {Authorization : `Bearer  ${refreshToken}`}})
                .then(response => {
                    // console.log('successTxt');
                    localStorage.setItem('norefresh', true); 
                    if (response?.data?.status === 401) {
                        // console.log('successTxt1223');
                        window.location.reload('/login');
                        localStorage.removeItem('token');
                        localStorage.removeItem('refresh_token');
                        localStorage.removeItem('tokenexpires');
                        resolve(false);
                    } else {
                        // console.log('successTxtNormal');
                        const accessToken = response.data.token;
                        const refreshToken = response.data.refreshToken;
                        const tokenExpires = response.data.tokenExpires
                        localStorage.setItem('token', accessToken);
                        localStorage.setItem('refresh_token', refreshToken);
                        localStorage.setItem('tokenexpires', tokenExpires);
                        inMemoryJWT.setToken(accessToken, refreshToken, tokenExpires);
                        resolve(true);
                    }
                    refreshTokenMulti = false;
                })
                .catch(error => {
                    // console.log('ErrorTxt');
                    localStorage.setItem('norefresh', true); 
                    // console.log('refresTokenEror');
                    // console.log(error.response);
                    if (error?.response?.data === 401) {
                        // console.log('expired refreshToken');
                        // window.location.reload('/login');
                        localStorage.removeItem('token');
                        localStorage.removeItem('refresh_token');
                        localStorage.removeItem('tokenexpires');
                    }
                    refreshTokenMulti = false;
                });
            
            });
        }
    };
  

    const getToken = () => {
      return inMemoryJWT = localStorage.getItem('token');
    }

    const setToken = (token, refresh_token, tokenExpiry) => {
        let currentToken = localStorage.setItem('token', token);
        let referesh_token =  localStorage.setItem('refresh_token', refresh_token);
        let tokenexpiry = localStorage.setItem('tokenexpires', tokenExpiry);
        inMemoryJWT = token;
        if(inMemoryJWT === ''){
            inMemoryJWT = currentToken;
        }
        //refreshToken(referesh_token, tokenExpiry);
        return true;
    };

    const ereaseToken = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        inMemoryJWT = null;
        //abordRefreshToken();
        window.localStorage.setItem(logoutEventName, Date.now());
        return true;
    }

    // This listener will allow to disconnect a session of ra started in another tab
    window.addEventListener('storage', (event) => {
        if (event.key === logoutEventName) {
            inMemoryJWT = null;
        }
    });

    return {
        ereaseToken,
        getRefreshedToken,
        getToken,
        setLogoutEventName,
        setRefreshTokenEndpoint,
        setToken,
        waitForTokenRefresh,
        refreshTokenGen,
    }
};

export default inMemoryJWTManager();


export const refreshTokenGen = (refreshToken) => {
    let refreshTokenMulti = localStorage.getItem('norefresh'); 
    if(refreshTokenMulti){
        localStorage.setItem('norefresh', false); 
        return new Promise((resolve, reject) => {
            axios.post(process.env.REACT_APP_API_URL + '/auth/refresh', void 0, {headers: {Authorization : `Bearer  ${refreshToken}`}})
            .then(response => {
                // console.log('successTxt');
                localStorage.setItem('norefresh', true); 
                if (response?.data?.status === 401) {
                    // console.log('successTxt1223');
                    window.location.reload('/login');
                    localStorage.removeItem('token');
                    localStorage.removeItem('refresh_token');
                    localStorage.removeItem('tokenexpires');
                    resolve(false);
                } else {
                    // console.log('successTxtNormal');
                    const accessToken = response.data.token;
                    const refreshToken = response.data.refreshToken;
                    const tokenExpires = response.data.tokenExpires
                    localStorage.setItem('token', accessToken);
                    localStorage.setItem('refresh_token', refreshToken);
                    localStorage.setItem('tokenexpires', tokenExpires);
                   // inMemoryJWTManager.setToken(accessToken, refreshToken, tokenExpires);
                    resolve(true);
                }
                refreshTokenMulti = false;
            })
            .catch(error => {
                // console.log('ErrorTxt');
                localStorage.setItem('norefresh', true); 
                // console.log('refresTokenEror');
                // console.log(error);
                if (error?.response?.data === 401) {
                    // console.log('expired refreshToken');
                    window.location.reload('/admin/login');
                    localStorage.removeItem('token');
                    localStorage.removeItem('refresh_token');
                    localStorage.removeItem('tokenexpires');
                }
                refreshTokenMulti = false;
            });
        
        });
    }
};