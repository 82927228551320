import { useState, useEffect } from 'react';
import * as Yup from "yup";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import DatePicker from "react-datepicker";
import {getStateData, selectFieldData} from '../utils/index';
import { useNavigate } from 'react-router-dom';
import {refreshTokenGen} from '../../../inMemoryJwt.js'
import { useSelector, useDispatch } from 'react-redux';
import department from './department.json';
import town from './town.json';
import { searchEnabled, searchFormEle } from '../../../store/actions/caseContentAction.js';
import {downloadFile, addBodyClass, setAuthorizationToken, dateConvert} from '../../common/utils';
import Select from 'react-select'

function SearchForm(props) {
  let navigate = useNavigate();
    const dispatch = useDispatch();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [subThemeDis, setSubThemeDis] = useState(true);
  const [subVilleDis, setVilleDis] = useState(true);
  const [fromError, setfromError] = useState('');
  const [formLoading, setLoading] = useState(false);
  const [villeData, setVilleData] = useState([]);
  const [theme, setTheme] = useState([]);
  const [subTheme, setSubTheme] = useState([]);
  const [dealer, setDealer] = useState([]);
  const [produit, setProduit] = useState([]);
  const [keywords, setkeywords] = useState([]);
  const [subThemeVal, setSubThemeVal] = useState('');
  const [serviceVal, setService] = useState('');
  const [dealerVal, setDealerVal] = useState('');
  const [villeDataVal, setvilleDataVal] = useState('');
  const [dptVal, setdptVal] = useState('');
  const [isTownLoading, setIsTownLoading] = useState(true);

useEffect(() => {
  getFilterData();
}, []);
  const getFilterData = () => {
    const token = localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refresh_token');
    if(token){
    axios.get(process.env.REACT_APP_API_URL + '/event/filters', { headers: setAuthorizationToken(token) })
      .then((res) => {
        if (res?.status === 401) {
            refreshTokenGen(refreshToken).then(challenge => {
                if(challenge){
                  getFilterData();    
                }
            })
        } else if(res.data){
            if(res?.data?.themes){
                let storeTheme = res?.data?.themes;
                let themeArray = [];
                storeTheme.filter((item, index) => {
                  let themeObject = {};
                    themeObject.label = item?.title;
                    themeObject.value = item?.id;
                    themeObject.children = item?.children;
                    themeArray.push(themeObject);
                });
              setTheme(themeArray);
            } 
            if(res?.data?.services){
              let modifiyData = selectFieldData(res?.data?.services);
              setProduit(modifiyData);
            }
            if(res?.data?.tags){
              let modifiyData = selectFieldData(res?.data?.tags);
              setkeywords(modifiyData);
            }
            if(res?.data?.users){
              let modifiyData = selectFieldData(res?.data?.users, true);
              setDealer(modifiyData);
            }
        }
      })
      .catch((err) => {
        if (err.response?.status === 401) {
            refreshTokenGen(refreshToken)?.then(challenge => {
                if(challenge){
                  getFilterData();    
                }
            })
        } 
      });
    }
  }
  const updateStartDate = (date, setFieldValue) => {
      console.log(date);
      setStartDate(date);
      let tmrDate = new Date(date);
      tmrDate = tmrDate.setDate(tmrDate.getDate() + 1)
      setEndDate(tmrDate);
      setFieldValue('endDate', tmrDate);
  }
 const themeUpdate = (e, setFieldValue) => {
    if(e.value !== 'Thème'){
      let themeData  = theme;
      let subThemeData;
      themeData?.filter((item, index) => {
          if(item.value === e.value){
            subThemeData =  themeData[index]?.children; 
          }
      });
      let modifiyData = selectFieldData(subThemeData);
      setSubTheme(modifiyData);
      setSubThemeDis(false);
      setSubThemeVal('')
      setFieldValue("subtheme", '');
    } else {
      setSubThemeDis(true);
    }
 }
 const GetVille = async(stateVal) => {
  let depTokenExpires = localStorage.getItem('departmentexpires');
  if(isNaN(parseInt(depTokenExpires)) || parseInt(depTokenExpires)  < new Date().getTime()){
      axios.get("https://www.universal-tutorial.com/api/getaccesstoken", { headers: {"api-token": "PRLTKyQ3gRHtx9K6I1LuJZ51YTTpavyOYXy6-RHQKr3sc9jqR460mMkNNHVvR7jkgoY", "user-email": "vinothkumarb936@gmail.com" }})
      .then(response => {
        let dt = new Date();
        dt.setHours(dt.getHours() + 2);
        dt = new Date(dt).getTime();
        localStorage.setItem('departmentexpires', dt);
        let auth = response.data.auth_token;
        localStorage.setItem('departmentToken', auth);
        getStateFileData(stateVal, auth);
      })
      .catch(error =>{
        console.log(error);
      }) 
  } else {
    let depToken= localStorage.getItem('departmentToken');
    getStateFileData(stateVal, depToken);
  }
}

const getStateFileData = (department, auth) => {
    var villeList = [];
    axios.get(` https://www.universal-tutorial.com/api/cities/${department}`, { headers: {Authorization: "Bearer " +  auth}})
    .then(response => {
            const stateList = response.data;
            if(stateList.length > 0){
                for(var i = 0; i < stateList.length; i++){
                        let eachItem = stateList[i].city_name;
                        var newObj = {
                                "label":eachItem,
                                "value":eachItem
                        }
                       villeList.push(newObj);
                }
            } else{
                var newObj = {
                    "label":auth,
                    "value":auth
                }
             villeList.push(newObj);     
            }
            console.log(department);
            console.log(villeList);
            setVilleData(villeList); 
            setVilleDis(false);
            setIsTownLoading(false);
    })
    .catch(error =>{
        console.log(error);
      })
}

 const departmentUpdate = async(e, setFieldValue)  => {
     if(e && e?.value !== 'Département'){
      const townData = town[e.value];
      if(townData.length > 0){
        var villeList = [];
        for(var i = 0; i < townData.length; i++){
                let eachItem = townData[i].label;
                var newObj = {
                        "label":eachItem,
                        "value":eachItem
                }
               villeList.push(newObj);
        }
    } 
      setdptVal(e?.value || '');
      setVilleData(villeList); 
      setFieldValue('town', '');
      setvilleDataVal('');
      //  GetVille(e.value);
      //  console.log();
      setVilleDis(false);
      setIsTownLoading(false);
     } else {
      setdptVal('');
      setFieldValue('town', '');
      setvilleDataVal('');
       setVilleDis(true);
       setIsTownLoading(true);
     }
 }
return (
      <Formik
       initialValues={{ 
        title: '', 
        startDate:startDate,
        endDate:endDate,
        theme:'',
        subtheme:'',
        dpt:'',
        town:'',
        dealer:'',
        service:'',
        tags:[]
      }}
      validationSchema={Yup.object().shape({
        title: Yup.string()
        .required('Le titre est obligatoire.'),
        startDate: Yup.string()
        .required('La date de début est requise.'),
        endDate: Yup.string()
        .required('La date de fin est requise.'),
        theme: Yup.string()
        .required('Le thème est obligatoire.'),
      })}
       onSubmit={(values, actions) => {
            values.startDate = new Date(values.startDate);
            values.endDate = new Date(values.endDate);
         if(props.redirectPage){
          navigate('/case-content');
          dispatch(searchFormEle(values, true));
         // actions.resetForm();
        } else {
          dispatch(searchFormEle(values, true));
          setLoading(true);
         // actions.resetForm();
        }
       }}
     >
       {({ handleSubmit, handleChange, handleBlur, values, setFieldValue, errors }) => (
        <div className='searchFormContainer'>
         <Form onSubmit={handleSubmit}>
          <div className='row'>
              <div className='col-12 form-group'>
                  <Field className="form-control"  type="text" name="title"  placeholder="Titre" />
                  <ErrorMessage name="title" className='errorMsg' component="div" /> 
              </div>
          </div>
          <div className="row">
              <div className='col-6 form-group'>
                <div className='customdatePicker startdatePicker'>
                    <DatePicker dateFormat="dd/MM/yyyy" className="form-control" name="startDate" selected={startDate} onChange={(date, event) =>{updateStartDate(date, setFieldValue ); setFieldValue("startDate", date)}}  placeholderText="Date de début"/>
                    <ErrorMessage name="startDate" className='errorMsg' component="div" /> 
                </div>
              </div>
            <div className='col-6 form-group'>
                <div className='customdatePicker'>
                   <DatePicker dateFormat="dd/MM/yyyy" className="form-control" name="endDate" selected={endDate}  onChange={(date, event) =>{setEndDate(date);  setFieldValue("endDate", date)}} selectsEnd  minDate={startDate} placeholderText="Date de fin" /> 
                   <ErrorMessage name="endDate" className='errorMsg' component="div" /> 
                 </div>
            </div>
         </div>
         <div className="row">
            <div className='col-12 form-group'>
                <div className='customSelect'>
                  <Select as="select" options={theme} name="theme" placeholder="Thème" onChange={(e) => {themeUpdate(e, setFieldValue); setFieldValue("theme", e.value)}} className="form-control" placeholdertext="Thème">
                  </Select>
                  <ErrorMessage name="theme" className='errorMsg' component="div" /> 
             </div>
             </div>
         </div>
         <div className="row">
            <div className='col-12 form-group'>
                <div className='customSelect'>
                    <Select as="select" value={subTheme.filter(function(option) { return option?.value === subThemeVal })}  options={subTheme} name="subtheme" onChange={(e) => {setFieldValue("subtheme", e?.value || ''); setSubThemeVal(e?.value || '')}}  isDisabled={subThemeDis}  className="form-control" placeholder="Sous-thème">
                  </Select>
                  <ErrorMessage name="subtheme" className='errorMsg' component="div" /> 
                </div>
             </div>
         </div>
         <div className="row">
            <div className='col-12 form-group'>
            <div className='customSelect'>
                  <Select as="select" name="dpt" value={JSON.parse(JSON.stringify(department)).filter(function(option) { return option?.value === dptVal })} isClearable options={JSON.parse(JSON.stringify(department))}  onChange={(e) => {departmentUpdate(e, setFieldValue); setFieldValue('dpt', e?.value || '') }} className="form-control" placeholder="Département">
                   
                </Select>
                </div>
             </div>
         </div>
         <div className="row">
            <div className='col-12 form-group'>
            <div className='customSelect'>
                <Select as="select" options={villeData} value={villeData.filter(function(option) { return option?.value === villeDataVal })} isClearable loadingMessage={'Chargement des données de la ville...'} isLoading={isTownLoading} name="town" isDisabled={subVilleDis} onChange={(e) => {setFieldValue('town', e?.value || '');setvilleDataVal(e?.value || '')}}   className="form-control" placeholder="Ville">
                   
                </Select>
                </div>
             </div>
         </div>
         <div className="row">
            <div className='col-12 form-group'>
            <div className='customSelect'>
                  <Select options={dealer} as="select" value={dealer.filter(function(option) { return option?.value === dealerVal })} isClearable name="dealer" onChange={(e) => {setFieldValue('dealer',e?.value || '');setDealerVal(e?.value || '')}}  className="form-control" placeholder="Concessionnaire">
                    
                </Select>
                </div>
             </div>
         </div>
         <div className="row">
            <div className='col-12 form-group'>
            <div className='customSelect'>
                <Select as="select" name="service" value={produit.filter(function(option) { return option?.value === serviceVal })} isClearable  options={produit} className="form-control" onChange={(e) => {setFieldValue('service',e?.value || '');setService(e?.value || '') }} placeholder="Produits/services">
                </Select>
                </div>
             </div>
         </div>
         <div className="row">
            <div className='col-12 form-group'>
            <div className='customSelect multiselect'>
                  <Select as="select"   isMulti name="tags" options={keywords} className="form-control" onChange={(e) => {console.log(e); setFieldValue('tags', e) }} placeholder="Mots-clés">
                    
                </Select>
                </div>
             </div>
         </div>
         <div className="row">
            <div className='col-12 form-group noMargin submitBtnCol'>
            <p className='noMargin text-center'>
              <button type="submit" className="submitBtn" >
                  <span className="formSearchIcon"><i className='icon-iconSearch iconmoon'></i></span>
                Recherche
            </button></p>
           </div>
          </div>
         </Form>
         </div>
        )}
     </Formik>
    )
}
export default SearchForm;