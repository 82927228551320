import { useEffect, useState } from 'react';
import Header from '../../header/index';
import Footer from '../../footer/index';
import {addBodyClass} from '../../common/utils';
import city from '../../common/searchForm/cities.json';
import { useAuthenticated  } from 'react-admin';
function MentionLegalesPage() {
    useAuthenticated();
    
    return (
        <>
        <Header/>
          <div className="breadCrumbSec">
              <div className="container siteContainer">
                    <div className='row'>
                      <div className='col-12'>
                          <ul className="resetList breadCrumbList">
                              <li><span>Mentions légales </span></li>
                          </ul>
                      </div>
                    </div>
              </div>
          </div>   
         <div className='pageDataSec mentionsDataSec'>
            <div className='container siteContainer'>
            <div className='metionLeagesPage'>
                    <div className="row">
                        <div className="col-12">
                            <div class="text-container">
                                <h1>Mentions légales</h1>
                                <div className="content">
                                    <p>
                                         Cette plateforme opérée par Puck l’Agence est destinée au réseau des concessionnaires Case IH pour la gestion des opérations autour du magazine Farm Forum.
                                    </p>
                                    <p>
                                         Son objectif est de collecter les informations pour la personnalisation du magazine à l’enseigne de chaque concessionnaire et doit permettre de transmettre directement chacune de leurs bases de données au routeur.
                                    </p>
                                    <p>
                                         Elle permettra en outre de stocker et de conserver les informations pour les numéros suivants.
                                    </p>
                                    <p className="agency">
                                         @pucklagence 2024
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
</div>
                      
                </div>
          </div>
        <Footer/>
        </>
    );
  }
export default MentionLegalesPage;