import * as React from 'react';
import axios from 'axios';
import { setAuthorizationToken } from '../../common/utils';
import inMemoryJWT from '../../../inMemoryJwt';
import Grid from '@mui/material/Grid';
import { TabbedForm, List, Edit, ShowButton, Show,Labeled, BooleanInput,BooleanField, SimpleShowLayout, ImageField, ArrayField, ReferenceInput, SelectInput, ReferenceManyField, FileInput, FileField, Datagrid, Button, ArrayInput, SimpleFormIterator, NumberInput, TextField, DateField, Create, SimpleForm, TextInput, DateInput, required,useRecordContext, EditButton } from 'react-admin';

export const serviceCreate = () => (
    <Create redirect="list">
        <SimpleForm > 
            <TextInput source="title" sx={{width:'50%'}} multiline={true} label="Title"  validate={[required()]} />
        </SimpleForm>
    </Create>
);
const ServiceTitle = () => {
    const record = useRecordContext();
    return <span>Service / {record ? `${record.title}` : ''}</span>;
};
export const serviceEdit = () => (
    <Edit title={<ServiceTitle />}>
       <SimpleForm redirect="list">
            <TextInput source="title" sx={{width:'50%'}} validate={required()} />
        </SimpleForm>
    </Edit>
);
export const serviceShow = () => (
    <Show title={<ServiceTitle />}>
       <SimpleShowLayout >
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Labeled label="Service ID">
                        <TextField source="id"  label="ID"/>
                    </Labeled>
                </Grid>
                <Grid item xs={6}>
                    <Labeled label="Title">
                        <TextField source="title" label="Title" />
                    </Labeled>
                </Grid>
            </Grid>
        </SimpleShowLayout>
    </Show>
);
export const serviceList = () => (
    <List>
        <Datagrid>
            <TextField source="id"  label="ID"/>
            <TextField source="title" label="Title" />
            <EditButton />
            <ShowButton />
        </Datagrid>
    </List>
);